<template>
    <div>
       
        <h1>第二个网页</h1>
       
       
    </div>
</template>
<script>
  
    export default {
        components:{
       
        }
    }
</script>

<style lang="less" scoped>

</style>